import {
  useQuery,
  useInfiniteQuery,
  InfiniteQueryObserverOptions,
  UseQueryOptions,
  UseBaseQueryOptions,
} from '@tanstack/react-query';

import { userApi } from 'src/api';
import {
  GetMeEventsParams,
  GetMeEvents,
  CustomMeEvent,
  GetMeTicketsParams,
  GetMeTickets,
  GetPlacesResponse,
  GetBankAccountStatusResponse,
} from 'src/types/api';
import { User, BillingInfo, EventPromoter, BillingDataStatus } from 'src/types/dto';

import { useUserStore } from '../stores';
import { useUpdaterQuery } from './utils';

const pageLimit = 12;

const getMe = () => userApi.get<User>('me').then(res => res.data);

const getMeBillingData = () =>
  userApi.get<BillingInfo>('me/billingdata').then(res => res.data);

const getMeBankAccountStatus = (userId: number) =>
  userApi.get<BillingDataStatus>(`me/billingdata/check?userId=${userId}`).then(res => res.data);

const getMePromoterEvents = () =>
  userApi.get<EventPromoter[]>('promoter/me').then(res => res.data);

const getMePlaces = () =>
  userApi
    .get<GetPlacesResponse>('place', { params: { limit: 6 } })
    .then(res => res.data);

export const getMeEvents = async ({
  offset,
  isPromoter,
  userData,
}: GetMeEventsParams) => {
  const events = await userApi
    .get<GetMeEvents>('me/events', {
      params: { limit: pageLimit, offset: offset ?? 0 },
    })
    .then(res => res.data);
  if (isPromoter) {
    const promoterEvents = await getMePromoterEvents();

    const mapped: CustomMeEvent[] = promoterEvents.map(item => ({
      id: item.eventId,
      userId: 0,
      userImgUrl: item.userImgUrl,
      name: item.eventName,
      schedules: item.eventSchedules,
      sessions: item.eventSchedules,
      ticketTypes: item.ticketTypes?.map(ticketType => ({
            id: ticketType.id,
            eventId: '',
            name: '',
            batch: null,
            description: ticketType.description,
            ticketGroup: null,
            sex: null,
            priceType: null,
            scheduledBatchTicketTypeId: ticketType.scheduledBatchTicketTypeId,
            quantity: ticketType.quantity,
            reserved: ticketType.reserved,
            quantityUnavailable:
              ticketType.quantityAvailable - ticketType.soldQuantity,
            hasSell: ticketType.soldQuantity > 0,
            forSale: true,
            free: false,
            price: ticketType.sellPrice,
            tax: ticketType.taxPrice,
            sellStartTicket: null,
            sellStart: '',
            sellEnd: ticketType.sellEnd,
            sessions: [],
          })) ?? undefined,
      categoryId: 0,
      creationDate: '',
      publicationStatus: item.eventStatus,
      place: {
        id: 0,
        name: item.eventPlace,
        state: '',
        city: '',
        district: '',
        street: '',
        streetNumber: 0,
        complement: '',
        latitude: 0,
        longitude: 0,
      },
      totalQuantity: item.totalQuantity ? item.totalQuantity : undefined,
      totalSold:
        item.totalSold !== 0
          ? item.totalSold
          : item.ticketTypes?.reduce(
              (acc, ticketType) => acc + ticketType.soldQuantity,
              0,
            ) ?? 0,
      level: 'promoter' as const,
    }));
    const eventIds = events.items.map(event => event.id);
    return mapped.reduce<GetMeEvents>((eventsResponse, event) => {
      return eventIds.includes(event.id)
        ? {
            count: eventsResponse.count,
            items: eventsResponse.items.map(arrEvent =>
              arrEvent.id === event.id
                ? {
                    ...arrEvent,
                    level:
                      arrEvent.userId === userData?.id
                        ? 'organizer-promoter'
                        : 'staff-promoter',
                  }
                : arrEvent,
            ),
          }
        : {
            count: eventsResponse.count,
            items: [event, ...eventsResponse.items],
          };
    }, events);
  }
  return events;
};

const getMeTickets = ({ offset }: GetMeTicketsParams) =>
  userApi
    .get<GetMeTickets>('me/tickets', { params: { limit: pageLimit, offset } })
    .then(res => res.data);

export const useMeQuery = (options?: Partial<UseBaseQueryOptions>) => {
  const { email } = useUserStore();

  const query = useUpdaterQuery<User>({
    queryKey: ['me', email],
    queryFn: getMe,
    staleTime: Infinity,
    enabled: !!email && (options?.enabled ?? true),
  });

  return query;
};

export const useMeBillingDataQuery = (options = {}) => {
  const { email } = useUserStore();

  const query = useUpdaterQuery({
    queryKey: ['me/billingdata', email],
    queryFn: getMeBillingData,
    staleTime: Infinity,
    ...options,
  });

  // if (!email) query.update(null);

  return query;
};

export const useMeEventsInfiniteQuery = (
  options: InfiniteQueryObserverOptions<GetMeEvents, Error>,
) => {
  const { data } = useMeQuery();

  return useInfiniteQuery<GetMeEvents, Error>({
    queryKey: ['me/events', data?.email],
    initialPageParam: 0,
    queryFn: ({ pageParam = 0 }) =>
      getMeEvents({
        offset: pageParam as number,
        isPromoter: !!data?.promoter,
        userData: data,
      }),

    getNextPageParam: (lastPage, allPages) => {
      const remainingEvents =
        lastPage.count -
        allPages.reduce((acc, page) => acc + page.items.length, 0);
      const nextPageParam =
        remainingEvents > 0 ? allPages.length * pageLimit + 1 : undefined;
      return nextPageParam;
    },
  });
};

export const useMeTicketsInfiniteQuery = (
  options: InfiniteQueryObserverOptions<GetMeTickets, Error>,
) => {
  const { data } = useMeQuery();

  return useInfiniteQuery<GetMeTickets, Error>({
    queryKey: ['me/tickets', data?.email],
    initialPageParam: 0,
    queryFn: ({ pageParam = 0 }) =>
      getMeTickets({ offset: pageParam as number }),
    getNextPageParam: (lastPage, allPages) =>
      allPages.length < Math.ceil(lastPage.count / pageLimit)
        ? allPages.length * pageLimit
        : undefined,
    // ...options,
  });
};

export const useMePromoterEventsQuery = (
  options: UseQueryOptions<EventPromoter[], Error>,
) => {
  const { data } = useMeQuery();

  const { queryKey: incomingKeys = [], ...restOfOptions } = options;

  return useQuery<EventPromoter[], Error>({
    queryKey: ['me/promoter-events', data?.email, ...incomingKeys],
    queryFn: getMePromoterEvents,
    enabled: !!data?.promoter && (options.enabled ?? true),
    ...restOfOptions,
  });
};

export const useMePlaces = (
  // options: UseQueryOptions<GetPlacesResponse, Error> = { queryKey: [] },
  options?: Partial<UseBaseQueryOptions>,
) => {
  const { data } = useMeQuery();

  const query = useUpdaterQuery<GetPlacesResponse>({
    queryKey: ['me/places', data?.email],
    queryFn: getMePlaces,
    // ...options,
  });

  // if (!data) query.update(null);

  return query;
};


export const useMeAccountStatusQuery = (
  // options: UseQueryOptions<GetPlacesResponse, Error> = { queryKey: [] },
  options?: Partial<UseBaseQueryOptions>,
) => {
  const { data: MeData } = useMeQuery();

  const query = useUpdaterQuery<GetBankAccountStatusResponse>({
    queryKey: ['getMeBankAccountStatus', MeData?.id],
    queryFn: () => getMeBankAccountStatus(MeData?.id),
    enabled: options?.enabled
        // ...options,
  });

  // if (!data) query.update(null);

  return query;
};
